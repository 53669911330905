export const version = process.env.VUE_APP_VERSION

// TODO：接口通用请求参数—待废弃
export const params = {
  version
}

// 兼容旧导出方式
export default {
  version
}
