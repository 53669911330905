export default [{
  path: '/',
  component: () => import('@/views/layout'),
  children: [{
    path: '/',
    name: 'index',
    component: () => import('@/views/home/home'),
    meta: {
      title: 'chuansheng'
    }
  },{
    path: '/news',
    name: 'news',
    component: () => import('@/views/home/news'),
    meta: {
      title: 'chuansheng'
    }
  },{
    path: '/games',
    name: 'games',
    component: () => import('@/views/home/games'),
    meta: {
      title: 'chuansheng'
    }
  },{
    path: '/about',
    name: 'about',
    component: () => import('@/views/home/about'),
    meta: {
      title: 'chuansheng'
    }
  },{
    path: '/privacy',
    name: 'privacy',
    component: () => import('@/views/home/policy/privacyPolicy'),
    meta: {
      title: 'chuansheng'
    }
  },{
    path: '/termofservice',
    name: 'termofservice',
    component: () => import('@/views/home/policy/service'),
    meta: {
      title: 'chuansheng'
    }
  }]
}]
