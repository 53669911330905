import Vue from 'vue'
import Router from 'vue-router'
// import store from '@/store'
import home from './home'

Vue.use(Router)

const routes = [
  ...home,
]

// 定义路由实例
const router = new Router({
  mode: 'history',
  base: '/',
  routes,
  scrollBehavior (to, from, savedPosition) { // 路由切换保持滚动条回到顶部
    // return savedPosition || to.hash || {x: 0, y: 0}
    return savedPosition || { x: 0, y: 0 }
  }
})

export default router